<template>

	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>产品管理</p>
			<i>/</i>
			<p @click="jumpPage">产品分类列表</p>
			<i>/</i>
			<p class="active">产品分类编辑</p>
		</nav>

		<div class="page_edit">

			<div class="page_edit_form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="" prop="name">
						<p class="form_label_p">分类名称</p>
						<el-input class="input_long" v-model="ruleForm.name" placeholder="请输入产品分类名称(文本20)">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="alias">
						<p class="form_label_p">别称</p>
						<el-input class="input_long" v-model="ruleForm.alias" placeholder="请输入英文+或英文+数字">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="sort">
						<p class="form_label_p">排序</p>
						<el-input class="input_long" v-model="ruleForm.sort" placeholder="请输入排序(整数越小越靠前)">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="">
						<p class="form_label_p">状态</p>
						<el-radio-group v-model="ruleForm.status">
							<el-radio :disabled="disabled" label="1">激活</el-radio>
							<el-radio :disabled="disabled" label="0">冻结</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item class="el-form-item-preservation">
						<div class="page_edit_preservation">
							<p class="p1" @click="upPage">返回</p>
							<p class="p2" v-if="modifyBtn" @click="createForm('ruleForm')">保存</p>
							<p class="p2" v-else @click="editForm('ruleForm')">保存</p>
						</div>
					</el-form-item>
				</el-form>
			</div>

		</div>

	</div>


</template>

<script>
	export default {
		name: 'goodsClassEdit',
		data() {
			return {
				ruleForm: {
					name: '',
					alias: '', // 别称
					sort: '', // 排序
					status: '1', // 状态
				},
				rules: {
					name: [
						{
							required: true,
							message: '名称不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^[\u4e00-\u9fa5]{0,}$/,
							message: '只能是汉字',
						}
					],
					alias: [{
							required: true,
							message: '别称不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^\w+$/,
							message: '简称只能为英文和数字',
						}
					],
					sort: [{
						pattern: /^\+?[1-9][0-9]*$/,
						message: '只能是非0的正整数',
					}]
				},
				modifyBtn: true, // 默认创建
				disabled: false, // 默认可修改
			}
		},
		components: {},
		created() {
			if (this.$route.query.id) {
				this.modifyBtn = false;

				// 获取分类做修改
				this.getGoodsClass()
			}
		},
		methods: {

			// 获取分类做修改
			getGoodsClass() {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}category/detail`,
					data: {
						id: this.$route.query.id,
					}
				}).then(res => {
					if (res.code == '0') {
						this.ruleForm.name = res.data.cat_name;
						this.ruleForm.alias = res.data.alias;
						this.ruleForm.sort = res.data.sort;
						this.ruleForm.status = res.data.status;
					} else {
						this.$message.error(`${res.msg}`);
					}
				})
			},

			// 创建
			createForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {

						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}category/add`,
							data: {
								cat_name: this.ruleForm.name,
								alias: this.ruleForm.alias,
								sort: this.ruleForm.sort,
								status: this.ruleForm.status,
							}
						}).then(res => {
							if (res.code == '0') {
								this.$message({
									message: '创建成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 修改
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {

						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}category/edit`,
							data: {
								id: this.$route.query.id,
								cat_name: this.ruleForm.name,
								alias: this.ruleForm.alias,
								sort: this.ruleForm.sort,
								status: this.ruleForm.status,
							}
						}).then(res => {
							if (res.code == '0') {
								this.$message({
									message: '修改成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})


					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},

			// 头部跳转页面
			jumpPage() {
				this.$router.go(-1);
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}

	.form_img_up {
		padding: 24px;
		background-color: #fff;

		.title {
			font-size: 14px;
			color: rgba(0, 0, 0, .85);
			height: 44px;
			line-height: 44px;
		}

		nav {
			display: flex;
			justify-content: flex-start;

			li {
				width: 134px;
				height: 134px;
				border: 1px dashed rgba(0, 0, 0, 0.2);
				background-color: rgba(0, 0, 0, .02);
				margin-right: 16px;
				position: relative;

				.img_up_style {
					width: 134px;
					height: 134px;

					.p1 {
						font-size: 14px;
						color: rgba(0, 0, 0, .45);
						margin-top: 36px;
					}

					.p2 {
						width: 90px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border-radius: 2px;
						border: 1px solid rgba(0, 0, 0, .15);
						margin: 16px auto 0;
					}
				}

			}
		}


	}
</style>
